/* eslint-disable */
import React from "react";
import ClientData from "./ClientData";

const ClientLogos = () => {
  
  return (
    <div
      className="pr__clients__logos pr__section pr__dark uk-section uk-section-large uk-padding-remove-top"
      id="pr__clients__logos"
    >
      <div className="uk-container">
        <hr className="pr__hr__section" />
        <div className="section-outer uk-padding uk-padding-remove-bottom uk-padding-remove-horizontal">
          <div
            className="uk-flex uk-flex-around uk-flex-middle uk-grid uk-grid-large"
            data-uk-grid
          >
            {ClientData.map((client, index) => (
              <div className={`item logo ${index == 0 ? "uk-first-column" : ""}`} key={index}>
                <div className="inner">
                  <a href={client.link} target="_blank" rel="noopener noreferrer">
                  <img src={client.image} height={client.size.height} width={client.size.width} alt={client.name} data-uk-svg/>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLogos;
