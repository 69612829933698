/* eslint-disable */
import React from "react";

const Clients = () => {
  return (
    <section
      className="pr__clients pr__section pr__dark uk-section uk-section-large"
      id="pr__clients"
    >
      <div className="uk-container">
        <div className="section-outer">
          <div className="uk-flex uk-flex-middle uk-grid uk-grid-large" data-uk-grid="">
            <div className="right uk-width-expand">
              <div className="section-heading">
                <div className="inner">
                  <div className="left">
                    <hr className="line pr__hr__secondary" />
                    <h2 className="title uk-h1">What Clients Say.</h2>
                    <span className="subtitle pr__heading__secondary">From all over the world</span>
                  </div>
                </div>
              </div>
              <div className="section-inner">
                <div className="item client-box style-one">
                  <div className="outer">
                    <div className="inner">
                      <p className="description">
                        We are motivated by the satisfaction of our clients. Put your trust in us
                        and we bring your idea to life with the Asset of our  Management  made up of a team of expert,
                        committed and experienced people with a passion for better tech and software . Our
                        goal is to achieve continuous and sustainable growth of our clients.
                      </p>
                      <p className="client">Counsel Ayebare Kevin, CEO Mak&amp;Laid</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="left uk-width-2-5@m uk-flex-first uk-first-column">
              <div className="item client-box style-one client-image">
                <div
                  className="image pr__image__cover pr__ratio__portrait"
                  data-src="assets/images/lawyer2.jpg"
                  height="400"
                  width="400"
                  data-uk-img=""
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
