/* eslint-disable */
import React from "react";
import CloseButton from "./CloseButton";

const Form = () => {
  return (
    <div
      id="pr__contact__form"
      className="pr__contact__form pr__dark uk-modal-full uk-modal"
      data-uk-modal
    >
      <div className="uk-modal-dialog" data-uk-height-viewport="" style={{minHheight: "calc(100vh)"}}>
        <div className="form-outer">
          <div className="uk-container uk-container-xsmall">
            <div className="form-inner uk-position-relative">
              <button
                className="uk-modal-close-full uk-close uk-icon"
                type="button"
                data-uk-close="ratio: 2;"
              >
                <span>Close</span>
                {/* <CloseButton /> */}
              </button>
              <h2 className="uk-modal-title uk-h1">Let's Talk?</h2>
              <p>Let’s make something awesome together and For You </p>
              <form className="pr__contact pr__form" noValidate="novalidate">
                <div className="pr__form__group">
                  <label htmlFor="name">
                    Your Name <span className="required">*</span>
                  </label>
                  <input className="pr-input" id="name" name="name" type="text" />
                </div>
                <div className="pr__form__group">
                  <label htmlFor="email">
                    Your E-Mail <span className="required">*</span>
                  </label>
                  <input className="pr-input" id="email" name="email" type="text" />
                </div>
                <div className="pr__form__group">
                  <label htmlFor="subject">
                    Your Subject <span className="required">*</span>
                  </label>
                  <input className="pr-input" id="subject" name="subject" type="text" />
                </div>
                <div className="pr__form__group">
                  <label htmlFor="budget">What's your budget range?</label>
                  <select className="uk-select" id="budget" name="budget">
                    <option>&nbsp;</option>
                    <option>Low budget</option>
                    <option>$250 — $300</option>
                    <option>$350 — $450</option>
                    <option>$500 — $650</option>
                    <option>$800+</option>
                  </select>
                </div>
                <div className="pr__form__group">
                  <label htmlFor="message">
                    Your message <span className="required">*</span>
                  </label>
                  <textarea className="pr-textarea" id="message" name="message"></textarea>
                </div>
                <p className="pr__form__group uk-margin-large">
                  <button className="uk-button uk-button-large uk-button-primary" type="submit">
                    Send message
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
