/* eslint-disable */
import React from "react";

const Works = () => {
  return (
    <section
      className="pr__works pr__section pr__dark section-slider uk-section uk-section-large"
      id="pr__works"
    >
      <div className="section-outer">
        <div className="section-heading">
          <div className="uk-container">
            <div className="inner">
              <div className="left">
                <hr className="line pr__hr__secondary" />
                <h2 className="title uk-h1">Works.</h2>
                <span className="subtitle pr__heading__secondary">Things we've made</span>
              </div>
              <div className="right">
                <a className="button uk-button uk-button-default" href="#">
                  View all
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="section-inner">
          <div className="uk-container uk-container-no">
            <div
              className="items-listing works-boxes works-slider owl-carousel owl-loaded owl-drag"
              data-items="4"
              data-margin="30"
              data-loop="true"
              data-center="true"
              data-autoplay="true"
              data-dots="true"
            >
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transition: "all 0.45s ease 0s",
                    width: "5243px",
                    transform: "translate3d(-1814px, 0px, 0px)",
                  }}
                >
                  <div
                    className="owl-item cloned"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/02.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Bikeer</h3>
                          <p className="category">Design / Concept</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/04.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Woody</h3>
                          <p className="category">Website / Development</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/06.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Interior Design</h3>
                          <p className="category">Archticture / Design</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/05.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Electrica</h3>
                          <p className="category">UX Design / Web App</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="owl-item" style={{ width: "373.25px", marginRight: "30px" }}>
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/01.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Expedia Solutions</h3>
                          <p className="category">Marketing / App</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item active"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/02.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Bikeer</h3>
                          <p className="category">Design / Concept</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item active center"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/04.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Woody</h3>
                          <p className="category">Website / Development</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item active"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/06.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Interior Design</h3>
                          <p className="category">Archticture / Design</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item active"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/05.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Electrica</h3>
                          <p className="category">UX Design / Web App</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/01.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Expedia Solutions</h3>
                          <p className="category">Marketing / App</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/02.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Bikeer</h3>
                          <p className="category">Design / Concept</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/04.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Woody</h3>
                          <p className="category">Website / Development</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: "373.25px", marginRight: "30px" }}
                  >
                    <div className="item work-box">
                      <div className="outer">
                        <div
                          className="image pr__image__cover pr__ratio__square"
                          data-src="assets/images/works/06.jpg"
                          data-uk-img=""
                        ></div>
                        <div className="inner">
                          <h3 className="title uk-h4">Interior Design</h3>
                          <p className="category">Archticture / Design</p>
                          <a href="#" className="link uk-position-cover"></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-nav disabled">
                <button type="button" role="presentation" className="owl-prev">
                  <span aria-label="Previous">‹</span>
                </button>
                <button type="button" role="presentation" className="owl-next">
                  <span aria-label="Next">›</span>
                </button> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Works;
