const ClientData = [
  {
    name: "Mak&Laid",
    image: "assets/images/logos/logo_transparent_white.png",
    size : {
      width: "100px",
      height: "100px"
    },
    link: "https://www.makandlaid.com/",
  },
];

export default ClientData;