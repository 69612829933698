/* eslint-disable */
import React from "react";

const Preloader = () => {
  return (
    <div id="loader" className="preloader pr__dark">
      <span className="loading">
        <span className="txt">Loading</span>
        <span className="progress">
          <span className="bar-loading"></span>
        </span>
      </span>
    </div>
  );
};

export default Preloader;
