/* eslint-disable */
import React from "react";
import Preloader from "../components/Preloader";
import MobileNav from "../components/MobileNav";
import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Works from "../components/Works";
import Services from "../components/Services";
import About from "../components/About";
import Clients from "../components/Clients";
import ClientLogos from "../components/ClientLogos";
import Footer from "../components/Footer";
import Form from "../components/Form";

const Home = () => {
  return (
    <>
      <Preloader />
      <MobileNav />
      <div className="pr__wrapper" id="site-wrapper" style={{ top: 0 }}>
        <div
          className="pr__hero__wrap pr__dark"
          style={{ backgroundImage: "url('assets/images/hero_3.jpg')" }}
          id="site-hero"
        >
          <NavBar />
          <div
            className="uk-sticky-placeholder"
            style={{ height: "170px", margin: "0px" }}
            hidden
          ></div>
          <Hero />
          <Features />
        </div>
        <div className="pr__content" id="site-content">
          <hr className="pr__vr__section" />
          <Services />
          <hr className="pr__vr__section" />
          <Works />
          <hr className="pr__vr__section" />
          <About />
          <hr className="pr__vr__section" />
          <Clients />
          <hr className="pr__vr__section" />
          <ClientLogos />
          <hr className="pr__vr__section" />
        </div>
        <Footer />
      </div>
      <Form />
    </>
  );
};

export default Home;
