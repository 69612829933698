/* eslint-disable */
import React from "react";

const Hero = () => {
  return (
    <section className="pr__hero uk-section" id="pr__hero">
      <div className="uk-container">
        <div className="section-inner">
          <div className="hero-content uk-grid uk-grid-stack" data-uk-grid="">
            <div className="uk-width-2-3@s uk-first-column">
              <hr className="line pr__hr__primary" style={{ width: "70px" }} />
              <h2
                className="title uk-heading-hero"
                style={{ opacity: 1, transform: "translateY(0px)" }}
              >
                We're a tech Company.
              </h2>
              <a
                className="button uk-button uk-button-large uk-button-default uk-margin-top"
                href="#pr__services"
                data-uk-scroll=""
                style={{ opacity: 1, transform: "translateY(0px)" }}
              >
                Getting Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
