/* eslint-disable */

import React from "react";

const Footer = () => {
  return (
    <footer className="pr__footer" id="site-footer">
      <div className="pr__footer__top uk-section uk-section-large">
        <div className="section-outer">
          <div className="uk-container">
            <div className="section-inner">
              <div className="columns uk-grid" data-uk-grid="">
                <div className="pr__cta column">
                  <div className="inner">
                    <h2 className="title uk-h1">Let's Talk?</h2>
                  </div>
                </div>
                <div className="pr__cta column">
                  <div className="inner">
                    <a
                      id="pr__contact"
                      href="#pr__contact__form"
                      className="button uk-button uk-button-large uk-button-default"
                      data-uk-toggle=""
                    >
                      Make an enquiry
                    </a>
                  </div>
                </div>
                <div className="pr__social column uk-first-column">
                  <div className="inner">
                    <a href="#" className="icon pr-logo-facebook"></a>
                    <a href="#" className="icon pr-logo-twitter"></a>
                    <a href="#" className="icon pr-logo-instagram"></a>
                    <a href="#" className="icon pr-logo-xing"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pr__footer__center uk-section uk-section-small">
        <div className="uk-container">
          <ul>
            <li>
              <a href="tel:256752541359">
                Kampala, Uganda. 
                <span className="phone">+256 (0) 752 541 359</span> 
              </a>
              <a href="tel:256777861849">
                Kampala, Uganda.
                <span className="phone">+256 (0) 777 861 849</span>
              </a>
            </li>
            <li>
              <a href="mailto:info@abs-trak.com">
                Email us: <span className="phone">info@abs-trak.com </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="pr__footer__bottom uk-section">
        <div className="section-outer">
          <div className="uk-container">
            <div className="section-inner">
              <div className="columns uk-grid" data-uk-grid="">
                <div className="pr__links column">
                  <div className="inner">
                    <a href="#">Terms of use</a>
                    <a href="#">Privacy Policy</a>
                    <a href="#">Cookie Policy</a>
                  </div>
                </div>
                <div className="pr__copyrights column uk-first-column">
                  <div className="inner">
                    <p>
                      {new Date().getFullYear()} © <a href="/">ABS-TRAK</a>, All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
