/* eslint-disable */
import React from "react";

const Services = () => {
  return (
    <section
      className="pr__services pr__section pr__dark uk-section uk-section-large"
      id="pr__services"
    >
      <div className="section-outer">
        <div className="section-heading">
          <div className="uk-container">
            <div className="inner">
              <div className="left">
                <hr className="line pr__hr__secondary" />
                <h2 className="title uk-h1">Services.</h2>
                <span className="subtitle pr__heading__secondary">
                  We work with you, Not for you
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="section-inner">
          <div className="uk-container">
            <div
              className="items-listing services-boxes uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s"
              data-uk-grid=""
            >
              <div className="item service-box style-one uk-active uk-first-column">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-strategy"></i>
                  <h5 className="title uk-h5">Digital Strategy</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-browser"></i>
                  <h5 className="title uk-h5">UX/UI Design</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-presentation"></i>
                  <h5 className="title uk-h5">Product Design</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin uk-first-column">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-clipboard"></i>
                  <h5 className="title uk-h5">Content Strategy</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-tools"></i>
                  <h5 className="title uk-h5">Design &amp; Concept</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-chat"></i>
                  <h5 className="title uk-h5">Social Media</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin uk-first-column">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-target"></i>
                  <h5 className="title uk-h5">Media Planning</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-lightbulb"></i>
                  <h5 className="title uk-h5">Brand Identity</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-search"></i>
                  <h5 className="title uk-h5">SEO Optimization</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-search"></i>
                  <h5 className="title uk-h5">Software Development</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-search"></i>
                  <h5 className="title uk-h5">BootCamp</h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              <div className="item service-box style-one uk-grid-margin">
                <div className="inner">
                  <i className="overlay-icon icon pr-line-search"></i>
                  <h5 className="title uk-h5">Mobile Application Development </h5>
                  <i className="icon pr-arrow-right"></i>
                  <a href="#" className="link uk-position-cover"></a>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
