/* eslint-disable */
import React from "react";

const MobileNav = () => {
  return (
    <div
      className="pr__mobile__nav"
      id="navbar-mobile"
      data-uk-offcanvas="overlay: true; flip: true; mode: none"
    >
      <div className="uk-offcanvas-bar">
        <button className="uk-offcanvas-close" type="button" data-uk-close="ratio: 2;"></button>

        <nav className="menu" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
          <ul data-uk-scrollspy="target: > li; cls:uk-animation-slide-right; delay: 100; repeat: true;">
            <li>
              <a href="#pr__hero">Home</a>
            </li>
            <li>
              <a href="#pr__services">Services</a>
            </li>
            <li>
              <a href="#pr__works">Works</a>
            </li>
            <li>
              <a href="#pr__about">About</a>
            </li>
            <li>
              <a href="#pr__clients">Clients</a>
            </li>
            <li>
              <a href="#pr__contact">Contact</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileNav;
