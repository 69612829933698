/* eslint-disable */
import React from "react";

const NavBar = () => {
  return (
    <header
      className="pr__header pr__dark uk-sticky"
      data-uk-sticky="top: 100vh; animation: uk-animation-slide-top;"
    >
      <div className="uk-container">
        <div className="inner">
          <div className="logo" style={{opacity: 1, transform: "translateX(0px)"}}>
            <a href=".">
              <div className="brand">
                abs-trak
              </div>
            </a>
          </div>
          <div className="navbar pr-font-second">
            <nav className="menu" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
              <ul>
                <li style={{opacity: 1, transform: "translateX(0px)"}}>
                  <a href="#pr__hero">Home</a>
                </li>
                <li style={{opacity: 1, transform: "translateX(0px)"}}>
                  <a href="#pr__services">Services</a>
                </li>
                <li style={{opacity: 1, transform: "translateX(0px)"}}>
                  <a href="#pr__works">Works</a>
                </li>
                <li style={{opacity: 1, transform: "translateX(0px)"}}>
                  <a href="#pr__about">About</a>
                </li>
                <li style={{opacity: 1, transform: "translateX(0px)"}}>
                  <a href="#pr__clients">Clients</a>
                </li>
                <li style={{opacity: 1, transform: "translateX(0px)"}}>
                  <a href="#pr__contact">Contact</a>
                </li>
              </ul>
            </nav>
            <div className="lang" style={{opacity: 1, transform: "translateX(0px)"}}>
              <div data-uk-form-custom="target: true" className="uk-form-custom">
                <select>
                  <option value="English">En</option>
                </select>
                <span>En</span>
                <i className="icon pr-chevron-down"></i>
              </div>
            </div>
          </div>
          <div className="navbar-tigger" data-uk-toggle="target: #navbar-mobile">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
